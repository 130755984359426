import React, { createContext, useContext, useState } from "react";

const MainUIContext = createContext();

export function useMainUIContext() {
  return useContext(MainUIContext); 
}

export const MainUIConsumer = MainUIContext.Consumer;

export function MainUIProvider({ children }) {
  const [searchKey, setSearchKey] = useState('');
  const [enterSearchIcon, setEnterSearchIcon] = useState(false);
  const [checkoutShippingDetails, setCheckoutShippingDetails] = useState([]);


  const value = {
    searchKey,
    setSearchKey,
    enterSearchIcon, setEnterSearchIcon,
    checkoutShippingDetails, setCheckoutShippingDetails
  };

  return <MainUIContext.Provider value={value}>{children}</MainUIContext.Provider>;
}
