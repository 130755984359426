import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Col, Drawer, Dropdown, Row, Space } from "antd";
import {  useHistory } from "react-router-dom";
import NavMenu from "./NavMenu";
import { connect } from "react-redux";
import { Skeleton } from "antd";
import { getMenuItems } from "../utils/menuItems";
import { handleUserLogout } from "../utils/authUtils";
import HomeSearch from "./HomeSearch";

import { SearchOutlined, UserOutlined } from '@ant-design/icons';

const Logo = ({ logoClass, settingData, cartData, userDetail }) => {
  const [visible, setVisible] = useState(false);
  const history = useHistory();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const toggleDrawer = () => {
    setVisible(!visible); // Toggle visibility state
  };
  const [isLoading, setLoading] = useState(true);

  const items = getMenuItems(history, () => handleUserLogout(history));

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1365) {
        setVisible(false); // Close the drawer when the window is wider than 850px
      }
    };

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);
  
    return (
      <>
        <Space size={"large"} className=" menu-toggle-icon">
          {!userDetail?.user ? (
            <div
              onClick={() => {
                history.push(`/login`);
              }}
              style={{ cursor: "pointer", marginRight: 10 }}
            >
              Login / Signup
            </div>
          ) : (
            <Space
              size={"large"}
              className="icons-for-small-and-medium-devices"
            >
              <SearchOutlined
                style={{ fontSize: 24 }}
                className="cursor-pointer"
                onClick={toggleDrawer}
              />
              <Dropdown
                overlayClassName="header-profile-dropdown-form-small-devices"
                menu={{
                  items,
                }}
                trigger={["click"]}
                overlayStyle={{}}
              >
                <UserOutlined
                  style={{ fontSize: 24 }}
                  className="cursor-pointer"
                />
              </Dropdown>
            </Space>
          )}
          <Drawer
            placement="top"
            closable={true}
            visible={visible} // Controlled by state
            closeIcon={false}
            onClose={() => toggleDrawer(0, false)}
            headerStyle={{ display: "none", zIndex: 1000 }}
            width={300}
            height={500}
          >
            <Row justify={"space-between"}>
              <Col>
                <Link to={process.env.PUBLIC_URL + "/home"}>
                  <img
                    style={{ width: "97.582px", height: "30.341px" }}
                    alt="Logo"
                    src={process.env.PUBLIC_URL + "/media/icons/logo.svg"}
                  />
                </Link>
              </Col>
              <Col>
                <img
                  src={process.env.PUBLIC_URL + "/media/icons/crossicon.svg"}
                  alt="sidebar icon"
                  style={{
                    cursor: "pointer",
                    padding: "8.926px 8.939px 8.926px 8.94px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={toggleDrawer}
                />
              </Col>
            </Row>
            <div className="small-devices-search-bar">
              {isLoading ? (
                <Skeleton.Button
                  active
                  size="small"
                  shape="round"
                  style={{ marginTop: "20%", width: "200px" }}
                />
              ) : (
                <HomeSearch isMobile={true} />
              )}
            </div>
            <NavMenu isMobile={true} settingData={settingData} />
            <hr />
            <div className={`header-left-wrap-for-medium-devices `}>
              {!userDetail?.user ? (
                <div
                onClick={() => {
                  history.push(`/login`);
                }}
                style={{ cursor: "pointer", marginRight: 10 }}
              >
                Login / Signup
              </div>
              ) : (
                <div className="mobile-bottom-menu">
                  <div
                    style={{
                      position: "relative",
                      display: "inline-block",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        borderLeftColor: "#E6E6E6",
                      }}
                    >
                      <img
                        className="cart-icon-logo cursor-pointer"
                        src={`${process.env.PUBLIC_URL}/media/icons/cartonline.svg`}
                        onClick={() => {
                          history.push(`/cart`);
                        }}
                        alt="cart"
                      />
                      <div
                        className="cursor-pointer"
                        style={{
                          marginTop: "5px",
                          marginLeft: "10px",
                          marginRight: "10px",
                        }}
                        onClick={() => {
                          history.push(`/cart`);
                        }}
                      >
                        View Cart
                      </div>
                    </div>
                    <div
                    className="view-cart-count"
                      style={{
                        position: "absolute",
                        top: "-2px",
                        right: "86px",
                        backgroundColor: "#EA1E51",
                        color: "white",
                        borderRadius: "50%",
                        fontWeight: 500,
                        padding: "5px",
                        fontSize: "8px",
                        height: "18px",
                        // width: "22px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <span>
                        {cartData
                          .filter((el) => el.quantity > 0)
                          .reduce((total, item) => total + item.quantity, 0) <=
                        0
                          ? 0
                          : cartData
                              .filter((el) => el.quantity > 0)
                              .reduce(
                                (total, item) => total + item.quantity,
                                0
                              )}
                      </span>
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <Dropdown
                      className="header-profile-dropdown"
                      menu={{
                        items,
                      }}
                      trigger={["click"]}
                      visible={dropdownVisible}
                      onVisibleChange={setDropdownVisible}
                    >
                      <div
                        style={{
                          display: "flex",
                          borderLeftColor: "#E6E6E6",
                        }}
                      >
                        <img
                          className="profile-header-logo cursor-pointer"
                          src={`${process.env.PUBLIC_URL}/media/icons/useronline.svg`}
                          alt="profile"
                        />
                      </div>
                    </Dropdown>
                    <div
                      onClick={toggleDropdown}
                      className="cursor-pointer"
                      style={{
                        fontSize: "17px",
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "10px",
                      }}
                    >
                      <div style={{ color: "#808080" }}>Welcome to,</div>{" "}
                      {userDetail.user.business_name.split(" ")[0]}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Drawer>
        </Space>
        <div className={`${logoClass ? logoClass : ""}`}>
          <div style={{ display: "flex" }}>
            <img
              className="sidebaricon"
              src={process.env.PUBLIC_URL + "/media/icons/sidebaricon.svg"}
              onClick={toggleDrawer}
              alt="sidebar icon"
              style={{ cursor: "pointer", marginRight: "10px" }}
            />
          </div>
        </div>
      </>
    );
};

Logo.propTypes = {
  logoClass: PropTypes.string,
  settingData: PropTypes.object,
  cartData: PropTypes.array,
  userDetail: PropTypes.object,
};


const mapStateToProps = (state) => {
  return {
    settingData: state.settingData.ecom.basic,
    cartData: state.cartData,
    userDetail: state.userData,
  };
};

export default connect(mapStateToProps)(Logo);
