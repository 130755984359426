import React from "react";

export function Button({
  style = {},
  label,
  onClick,
  Icon,
  disabled,
  iconStyle = {},
  iconPosition = "after",
  className,
}) {
  return (
    <div className="center">
      <button
        onClick={onClick}
        disabled={disabled}
        style={{
          borderRadius: "32px",
          border: "none",
          fontFamily: "Montserrat",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          ...style,
        }}
        className={className}
      >
        {iconPosition === "before" && Icon && (
          <Icon style={{ marginRight: "8px", ...iconStyle }} />
        )}
        <span className="btn-label"> {label}</span>
        {iconPosition === "after" && Icon && (
          <Icon style={{ marginLeft: "8px", ...iconStyle }} />
        )}
      </button>
    </div>
  );
}

export default Button;
