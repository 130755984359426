// Package Block
import { Form, Spin, notification } from "antd";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { MetaTags } from "react-meta-tags";
import LayoutOne from "../../layouts/LayoutOne";
import { Link, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment";
import "moment-timezone";
import { useToasts } from "react-toast-notifications";
import { connect ,useSelector} from "react-redux";
import { ErrorMessage, Field, Formik } from "formik";

// Custom Components & Functions Block
import { Input } from "../../components/form/Input";
import PhoneInput from "../../components/form/PhoneInput";
import {
  TextError,
  customPagesPrivacyPolicyValidation,
  customPagesTermsValidation,
  customPagesTermsValidationCheck,
  getPriceRelatedToCustomer,
  loadScript,
  maskAccountNumber,
  validationMessageForTermsAndConditionAndPrivacyPolicy,
} from "../constant/utils";
import Button from "../../components/utils/Button";
import {
  fetchBillingCities,
  fetchBillingStates,
  fetchCities,
  fetchCounties,
  fetchStates,
} from "../../redux/actions/commonActions";
import AchModal from "./AchModal";
import {
  saleOrderPayload,
  paymentType,
  SHIPPING_TYPE,
} from "../constant/Constants";

// Redux Block
import { store } from "../..";
import {
  createSaleOrder,
  fetchFormValue,
  getSaleCalculation,
} from "../../redux/actions/checkoutActions";
import { deleteFromCart } from "../../redux/actions/cartActions";
import { onboardAch } from "../../redux/actions/loginActions";
import ShippingDetails from "./ShippingDetails/ShippingDetails";
import { bankDetail } from "../../redux/paymentCrud";
import PaymentMethod from "./PaymentMethod";
import CartFinal from "./CartFinal";


let _saleTotal = 0;
let _acceptedTerms = false;
let _paymentType = paymentType.COD_OA.value;
const noteEcomLength=500

const Checkout = ({
  cartItems,
  settings,
  sale,
  userDetail,
  adminSettings,
}) => {
  const {
    ecom_title,
    attributes,
    currencyRate,
    price_display_for_secure_user,
    price_display_for_unsecure_user,
  } = settings || {};

  const { customPages , loading} = useSelector((state) => ({
    customPages: state.generalData?.customPages,
    loading: state?.generalData?.loading
  }));

  const { currency_symbol, timezone } = adminSettings.basic
  const {  payments } = adminSettings.pos || {}
  const [loader, setLoader] = useState(false);
  const history = useHistory();
  const [openCustomerinfo, setCustomerinfo] = useState(true);
  const [openPaymentinfo, setPaymentinfo] = useState(false);
  const [AchDialog, setAchDialog] = useState(false);
  const [saleTotal, setSaleTotal] = useState(0);
  const [ calculation , setCalculations]= useState()
  const [savedAmount, setSavedAmount] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [salePayload, setSalePayload] = useState(saleOrderPayload);
  const [payment, setPayment] = useState(paymentType.COD_OA.value);
  const [isSelected, setIsSelected] = useState(false);
  const [actionsLoading, setActionsLoading] = useState(false);
  const [additionalNotes, setAdditionalNote] = useState("")
  const [currentPage, setCurrentPage] = useState(1);

  const { addToast } = useToasts();
  let additional_attributes = {};
  let additionalAttributeCondition = false;

  const formRef = useRef();
  const shippingBillingformRef = useRef();

  const [form] = Form.useForm();

  const [activeTab, setActiveTab] = useState(1);
  const handleClick = (tabNumber, paymentTypeValue) => {
    setPayment(paymentTypeValue);
    _paymentType = paymentTypeValue
    setActiveTab(tabNumber);
  };

  const buttonStyle = (tabNumber, width, font) => ({
    width: width,
    height: "44px",
    borderRadius: "32px",
    fontSize: font,
    marginTop: "16px",
    background: activeTab === tabNumber ? "#1A1A1A" : "#eb1e51",
    color: activeTab === tabNumber ? "#FFF" : "#333333",
    border: "1px solid #F2F2F2",
  });
  const handleNext = (values, handleSubmit) => {
    console.log("values1", values);
    // handleSubmit();
  };
  const [isDisabled, setIsDisabled] = useState(false);

  const openAchModal = (id) => {
    setAchDialog(true);
  };
  const openViewAchModal = (id) => {
    setIsDisabled(true);
    setAchDialog(true);
  };
  const onHide = () => {
    setIsDisabled(false);
    setAchDialog(false);
    getBankDetails();
  };

  useEffect(() => {
    let publickey;
    try {
      publickey = payments["3"]["1"]?.public_key;
    } catch (err) {
      publickey = "";
    }
    if (publickey) {
      loadScript(
        "https://secure.safewebservices.com/token/Collect.js",
        `${publickey}`
      )
        .then(() => {
          if (window.CollectJS) {
            window.CollectJS.configure({
              variant: "lightbox",
              callback: ({ token }) => {
                console.log("====================================");
                console.log(token);
                console.log("====================================");
                if (formRef?.current?.setFieldValue && token) {
                  formRef.current.setFieldValue(
                    "payment_detail.credit_debit_card.payment_token",
                    `${token}`
                  );
                  formRef.current.setFieldValue(
                    "payment_detail.credit_debit_card.type",
                    "sale"
                  );
                  placeOrder({ ...formRef?.current?.values });
                }
              },
            });
          }
        })
        .catch((error) => console.error(error.message));
    }
  
  }, []);

  const handleNMISetup = (event, values) => {
    event.preventDefault();
    if (window.CollectJS) {
      window.CollectJS.startPaymentRequest();
    }
  };

  const handleCheckout = () => {
    const validationMessage = validationMessageForTermsAndConditionAndPrivacyPolicy(customPagesTermsValidation(customPages), customPagesPrivacyPolicyValidation(customPages))
    if (!isSelected && activeTab===2) {
      addToast("Please accept the Terms and conditions", {
        appearance: "error",
        autoDismissTimeout: 2000,
        autoDismiss: true,
      });
      return;
    }else if (!isSelected && (activeTab ===1 || activeTab===3)) {
      addToast(validationMessage, {
        appearance: "error",
        autoDismissTimeout: 2000,
        autoDismiss: true,
      });
      return;
    }
  };

  useEffect(() => {
    // debugger
    // if (cartItems?.length > 0) {
    //   checkoutCalculation();
    // }

    if (attributes?.map) {
      attributes.map((attribute) => {
        if (attribute.is_active) {
          {
            additional_attributes[attribute.label] = "";
          }
        }
      })
    }
    setAddressDetail();
  }, [cartItems]);

const nextPage = () => {
    setCurrentPage(2);
    window.location.hash = 'root';
  };
  const prevPage = () => {
    setCurrentPage(1);
  };


  const checkoutCalculation = async (values) => {
    debugger
    if (!values?.shipping_detail.address_two) {
      delete values?.shipping_detail.address_two;
    }
    debugger
    setLoader(true);
    try {
      let sale_order_details = cartItems.map((item) => {
        return {
          product_id: item.id * 1,
          qty: item.quantity,
          unit: item.selected_unit_price.unit,
          unit_price: Number(getPriceRelatedToCustomer(userDetail, item)),
        };
      });
      let payload = {
        shipping_type: values.shipping_type ? values.shipping_type * 1 : 2,
        sale_order_details,
        ...(values.shipping_type == 2 && { shipping_detail: values.shipping_detail }),
      };
      const res = await getSaleCalculation(payload, userDetail?.authToken);
      setCalculations(res.data.entity)
      setSaleTotal(res.data.entity.total_price);
      setSavedAmount(res.data.entity.saved_amount)
      _saleTotal = res.data.entity.total_price;
      setLoader(false);
    } catch (err) {
      debugger
      console.log("error",err)
      setLoader(false);
      err.response.data.errors.map((error) =>
        notification.error({ message: error })
      );
      // history.push(`/shop`);
    }
  };

  const placeOrder = (values) => {
debugger
    if (values.shipping_detail.is_billing_same == "Y") {
      delete values.billing_detail;
    }
    if (!additionalAttributeCondition) {
      setActionsLoading(true);
      setDisabled(true);
      let payload = {
        ...values,
        accepted_terms: values.accepted_terms || _acceptedTerms,
        sale_order_details: cartItems.map((item) => {
          return {
            product_id: item.id * 1,
            qty: item.quantity,
            unit: item.selected_unit_price.unit,
            price: Number(getPriceRelatedToCustomer(userDetail, item)),
          };
        }),
        dispatch_date: moment.utc().format("YYYY-MM-DDTHH:mm:ss"),
        pickup_delivery_date: moment.utc().format("YYYY-MM-DDTHH:mm:ss"),
        source:  1,
        payment_detail: {
          ...salePayload.payment_detail,
          payments: [
            {
              amount: saleTotal || _saleTotal,
              payment_type: _paymentType,
              reference_number: values?.reference_number,
              payment_token:
                values?.payment_detail?.credit_debit_card?.payment_token,
              type: values?.payment_detail?.credit_debit_card?.type,
              memo: values?.memo || "",
            },
          ],
        },
      };
      debugger
      payload.shipping_type = payload.shipping_type * 1;
      if (payload.shipping_type === SHIPPING_TYPE.PICKUP.value) {
        delete payload.shipping_detail;
        delete payload.billing_detail;
      } else {
        if (!payload.shipping_detail.company_name) {
          delete payload.shipping_detail.company_name;
        }
        if (!payload.shipping_detail.telephone_num) {
          delete payload.shipping_detail.telephone_num;
        }
        if (!payload.shipping_detail.address_two) {
          delete payload.shipping_detail.address_two;
        }
      }
      paymentType === "CREDIT_DEBIT_CARD"
        ? store.dispatch(
          createSaleOrder(
            payload,
            userDetail,
            history,
            addToast,
            setDisabled,
            settings,
            setActionsLoading,
            handleCheckout
          )
        )
        : store.dispatch(
          createSaleOrder(
            payload,
            userDetail,
            history,
            addToast,
            setDisabled,
            setActionsLoading,
            settings,
            null
          )
        );
      store.dispatch(
        fetchFormValue({
          formData: {},
        })
      );
    }
  };

  const today = new Date();
  const numberOfDaysToAdd = 0;
  const date = today.setDate(today.getDate() + numberOfDaysToAdd);
  const defaultValue = new Date(date).toISOString().split("T")[0];

  const setAddressDetail = () => {
    store.dispatch(
      fetchStates({
        country:
          sale?.formData?.shipping_detail?.country ||
          userDetail.user.customer_shipping_details.country,
      })
    );
    store.dispatch(
      fetchCities({
        country:
          sale?.formData?.shipping_detail?.country ||
          userDetail.user.customer_shipping_details.country,
        state:
          sale?.formData?.shipping_detail?.state ||
          userDetail.user.customer_shipping_details.state,
      })
    );
    store.dispatch(
      fetchCounties({
        country:
          sale?.formData?.shipping_detail?.country ||
          userDetail.user.customer_shipping_details.country,
        state:
          sale?.formData?.shipping_detail?.state ||
          userDetail.user.customer_shipping_details.state,
      })
    );
    store.dispatch(
      fetchCounties({
        country:
          sale?.formData?.billing_detail?.country ||
          userDetail.user.customer_billing_details.country,
        state:
          sale?.formData?.billing_detail?.state ||
          userDetail.user.customer_billing_details.state,
      })
    );

    store.dispatch(
      fetchBillingStates({
        country:
          sale?.formData?.billing_detail?.country ||
          userDetail.user.customer_billing_details.country,
      })
    );
    store.dispatch(
      fetchBillingCities({
        country:
          sale?.formData?.billing_detail?.country ||
          userDetail.user.customer_billing_details.country,
        state:
          sale?.formData?.billing_detail?.state ||
          userDetail.user.customer_billing_details.state,
      })
    );

    let checkoutPayload = {
      ...formRef?.current?.values,
      additional_attributes: additional_attributes,
      pickup_delivery_date: defaultValue,
      shipping_detail: {
        ...formRef?.current?.values?.shipping_detail,
        name:
          sale?.formData?.shipping_detail?.name ||
          userDetail.user.customer_shipping_details.name,
        company_name:
          sale?.formData?.shipping_detail?.company_name ||
          userDetail.user.customer_shipping_details.company_name,
        telephone_num:
          sale?.formData?.shipping_detail?.telephone_num ||
          userDetail.user.customer_shipping_details.telephone_num,
        address:
          sale?.formData?.shipping_detail?.address ||
          userDetail.user.customer_shipping_details.address,
        // address_two:
        //   sale?.formData?.shipping_detail?.address_two ||
        //   userDetail.user.customer_shipping_details.address_two,
        country:
          sale?.formData?.shipping_detail?.country ||
          userDetail.user.customer_shipping_details.country,
        state:
          sale?.formData?.shipping_detail?.state ||
          userDetail.user.customer_shipping_details.state,
        tax_jurisdiction:
          sale?.formData?.shipping_detail?.tax_jurisdiction ||
          userDetail.user.customer_shipping_details.tax_jurisdiction,
        city:
          sale?.formData?.shipping_detail?.city ||
          userDetail.user.customer_shipping_details.city,
        zip_code:
          sale?.formData?.shipping_detail?.zip_code ||
          userDetail.user.customer_shipping_details.zip_code,
        delivery_instructions:
          sale?.formData?.shipping_detail?.delivery_instructions ||
          userDetail.user.customer_shipping_details.delivery_instructions,
        order_notes:
          sale?.formData?.order_notes ||
          userDetail.user.order_notes,
        is_billing_same:
          sale?.formData?.shipping_detail?.is_billing_same || "Y",
      },
      billing_detail: {
        ...formRef?.current?.values?.billing_detail,
        name:
          sale?.formData?.billing_detail?.name ||
          userDetail.user.customer_billing_details.name,
        company_name:
          sale?.formData?.billing_detail?.company_name ||
          userDetail.user.customer_billing_details.company_name,
        telephone_num:
          sale?.formData?.billing_detail?.telephone_num ||
          userDetail.user.customer_billing_details.telephone_num,
        address:
          sale?.formData?.billing_detail?.address ||
          userDetail.user.customer_billing_details.address,
        // address_two:
        //   sale?.formData?.billing_detail?.address_two ||
        //   userDetail.user.customer_billing_details.address_two,
        country:
          sale?.formData?.billing_detail?.country ||
          userDetail.user.customer_billing_details?.country ||
          "United States",
        state:
          sale?.formData?.billing_detail?.state ||
          userDetail.user.customer_billing_details.state,
        city:
          sale?.formData?.billing_detail?.city ||
          userDetail.user.customer_billing_details.city,
        zip_code:
          sale?.formData?.billing_detail?.zip_code ||
          userDetail.user.customer_billing_details.zip_code,
      },
    };
    setSalePayload(checkoutPayload);
    formRef?.current?.setValues && formRef.current.setValues(checkoutPayload);
  };
  const [loadingBank, setLoadingBank] = useState(false);
  const [bankInfo, setBankInfo] = useState({});
 
  const getBankDetails = async () => {
    setLoadingBank(true);
    try {
      const _bankDetailRes = await bankDetail();
      const bankDetailRes =
        _bankDetailRes.data.entity?.[0] || _bankDetailRes.data.entity;
      setBankInfo(bankDetailRes);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingBank(false);
    }
  };

  useEffect(() => {
    // getBankDetails
    getBankDetails();
  }, []);


  useEffect(()=>{
    if(!loading && !customPagesTermsValidationCheck(customPages)){
      setIsSelected(true);
      _acceptedTerms = true;
    }else if(customPagesTermsValidationCheck(customPages)){
      setIsSelected(false);
    } else if(!loading && (!customPagesTermsValidationCheck(customPages))){
      setIsSelected(false)
    } 
  }, [customPages])


  useEffect(()=>{
    if(!loading && !customPagesTermsValidationCheck(customPages) &&  activeTab===2){
      setIsSelected(false);
    }else if(!loading && !customPagesTermsValidationCheck(customPages) && (activeTab===1 || activeTab===3)){
      setIsSelected(true);
    }
  },[activeTab])

  return (
    <Fragment>
      <Spin spinning={loader}>
        <MetaTags>
          <title>{ecom_title} | Checkout</title>
          <meta
            name="description"
            content={`Checkout page of ${ecom_title} eCommerce template.`}
          />
        </MetaTags>
        <LayoutOne headerTop="visible">
          <div className="checkout-main-container">
            <>
              <div className="checkout-wrapper">
                {/* <h1>Checkout</h1> */}

                {/* Customer Information */}
                {currentPage == 1 &&
                <div>
                  <div className="customer-info-container">
                    <div className="customer-info-wrapper">
                      <h3 className="checkout_headings">
                        Customer Information
                      </h3>
                    </div>
                    {openCustomerinfo ? (
                      <div className="customer-details">
                        {/* Customer details for open state */}
                        <div className="customer-info1">
                          <h4>Customer Name</h4>
                          <p>{userDetail.user.business_name}</p>
                        </div>
                        <div className="customer-info1">
                          <h4>Email</h4>
                          <p>{userDetail.user.email}</p>
                        </div>
                        <div className="customer-info1">
                          <h4>Phone No</h4>
                          <p>{userDetail.user.business_phone_no}</p>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className="">
                          <div className="customized-row">
                            <div className="checkout-input-feilds">
                              <Field
                                type="text"
                                name="business_name"
                                component={Input}
                                placeholder="Contact Name*"
                                // label={<span>Business Name<span className="asterisk">*</span></span>}
                                label="Contact Name*"
                              />
                            </div>
                            <div className="checkout-input-feilds">
                              <Field
                                type="text"
                                name="dba_name"
                                component={Input}
                                placeholder="Email"
                                label="Email"
                              />
                            </div>
                            <div className="checkout-input-feilds">
                              {/* <label>Business Phone Number*</label>- */}
                              <PhoneInput
                                onChange={(value) => {
                                  // setFieldValue("business_phone_no", value);
                                }}
                                className="custom-phone-input"
                                inputProps={{
                                  name: "business_phone_no",
                                  // autoFocus: true,
                                }}
                              />
                              {
                                <ErrorMessage
                                  name="business_phone_no"
                                  component={TextError}
                                />
                              }
                            </div>
                          </div>
                          {/* <div className="customized-row1">
                            <Button
                              label="Next"
                              onClick={handleNext}
                              style={{
                                width: "261px",
                                height: "52px",
                                borderRadius: "32px",
                                marginTop: "16px",
                                background: "#EA1E51",
                                color: "#fff",
                              }}
                            />
                          </div> */}
                        </div>
                      </div>
                    )}
                  </div>
                  {/* Shipping Adress  */}
                  <ShippingDetails
                  formRef={formRef}
                  saleOrderPayload={salePayload}
                  setSalePayload={setSalePayload}
                  userDetail={userDetail}
                  settings={{ ...settings, timezone }}
                  onNext={nextPage}
                  checkoutCalculation={checkoutCalculation}
                  />
                 </div>
                  }
                  {currentPage == 2 &&
                  <div className="checkout-page-info">
              <div className="customer-info-container-two">
                {/* Payment methods */}
                <PaymentMethod
                  formRef={formRef}
                  openPaymentinfo={openPaymentinfo}
                  handleClick={handleClick}
                  buttonStyle={buttonStyle}
                  payment={payment}
                  calculation={calculation}
                  savedAmount={savedAmount}
                  activeTab={activeTab}
                  loadingBank={loadingBank}
                  bankInfo={bankInfo}
                  openAchModal={openAchModal}
                  openViewAchModal={openViewAchModal}
                  maskAccountNumber={maskAccountNumber}
                  saleTotal={saleTotal}
                  additionalNotes={additionalNotes}
                  setAdditionalNote={setAdditionalNote}
                  setPayment={setPayment}
                  prevPage={prevPage}
              />
              </div>
              <div className="customer-info-container-three">
              {/* Payment methods */}
              <CartFinal
                salePayload={salePayload}
                customPages={customPages}
                placeOrder={placeOrder}
                currency_symbol={currency_symbol}
                currencyRate={currencyRate}
                calculation={calculation}
                isSelected={isSelected}
                setIsSelected={setIsSelected}
                handleNMISetup={handleNMISetup}
                actionsLoading={actionsLoading}
                disabled={disabled}
                buttonStyle={buttonStyle}
                activeTab={activeTab}
                saleTotal={saleTotal}
                additionalNotes={additionalNotes}

            />
            </div>
            </div>
              }
              </div>
            </>
          </div>
        </LayoutOne>
      </Spin>
      {/* View ACH Modal */}
      {AchDialog && (
        <AchModal
          cb={() => store.dispatch(onboardAch())}
          show={AchDialog}
          onHide={onHide}
          setBankInfo={setBankInfo}
          isDisabled={isDisabled}
        />
      )}
    </Fragment>
  );
};

Checkout.propTypes = {
  cartItems: PropTypes.array,
  settings: PropTypes.object,
  sale: PropTypes.object,
  userDetail: PropTypes.object,
  deleteFromCart: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    cartItems: state.cartData,
    settings: state.settingData.ecom.basic,
    sale: state.saleData,
    userDetail: state.userData,
    adminSettings: state.settingData?.["admin-panel"] || { basic: { currency_symbol: "$" } },
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    deleteFromCart: (cartItems, item, addToast) => {
      dispatch(deleteFromCart(cartItems, item, addToast));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
