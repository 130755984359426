import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Dropdown } from "antd";
import { deleteFromCart } from "../../redux/actions/cartActions";
import { getMenuItems } from "../utils/menuItems";
import { handleUserLogout } from "../utils/authUtils";

const IconGroup = ({ cartData, userDetail }) => {
  const history = useHistory();
  const items = getMenuItems(history, () => handleUserLogout(history));
  

  

  const [cartDropdownVisible, setCartDropdownVisible] = useState(false);
  const [userDropdownVisible, setUserDropdownVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setCartDropdownVisible(false);
      setUserDropdownVisible(false);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [dropdownVisible, setDropdownVisible] = useState(false);

  // Function to toggle the visibility of the dropdown
  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };
  return (
    <div className={`header-right-wrap header-margin`}>
      {!userDetail?.user ? (
        <>
        <div style={{display:"flex", borderLeft:"2px solid", borderLeftColor:"#E6E6E6", marginRight:"10px", marginTop:"7px"}}>
        </div>
        <div
          onClick={() => {
            history.push(`/login`);
          }}
          style={{marginTop:"7px", cursor:"pointer"}}
        >
          Login / Signup
        </div>
        </>
      ) : (
        <>
          <div
            style={{
              position: "relative",
              display: "inline-block",
            }}
          >
            <div style={{display:"flex", borderLeft:"2px solid", borderLeftColor:"#E6E6E6"}}>
            <img
              className="cart-icon-logo cursor-pointer"
              src={`${process.env.PUBLIC_URL}/media/icons/cartonline.svg`}
              onClick={() => {
                history.push(`/cart`);
              }}
              alt="cart"
            />
            <div className="cursor-pointer" style={{marginTop:"5px", marginLeft:"10px", marginRight:"10px"}} onClick={() => {
                history.push(`/cart`);
              }}>View Cart</div>
            </div>
            <div
              style={{
                position: "absolute",
                top: "-8px",
                right: "8px",
                backgroundColor: "#EA1E51",
                color: "white",
                borderRadius: "50%",
                padding: "5px",
                fontSize: "12px",
                height: "22px",
                // width: "22px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span>
                {cartData.filter((el) => el.quantity > 0).reduce((total, item) => total + item.quantity, 0) <= 0 ? 0 :
                  cartData.filter((el) => el.quantity > 0).reduce((total, item) => total + item.quantity, 0)}
              </span>
            </div>
          </div>
          <Dropdown
            className="header-profile-dropdown"
            menu={{
              items,
            }}
            trigger={["click"]}
            visible={dropdownVisible}
            onVisibleChange={setDropdownVisible}
          >
            <div style={{display:"flex", borderLeft:"2px solid", borderLeftColor:"#E6E6E6"}}>
            <img
              className="profile-header-logo cursor-pointer"
              src={`${process.env.PUBLIC_URL}/media/icons/useronline.svg`}
              alt="profile"
            />
            </div>
          </Dropdown>
          <div onClick={toggleDropdown} className="cursor-pointer" style={{ fontSize: "17px", display: "flex", alignItems: "center", marginLeft:"10px", }}>
            <div style={{color:"#808080"}}>Welcome to,</div>{' '}
            {userDetail.user.business_name.split(" ")[0]}
          </div>
        </>
      )}
    </div>
  );
};

IconGroup.propTypes = {
  cartData: PropTypes.array,
  deleteFromCart: PropTypes.func,
  userDetail: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    cartData: state.cartData,
    userDetail: state.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteFromCart: (cartItems, item, channel_id, addToast) => {
      dispatch(deleteFromCart(cartItems, item, channel_id, addToast));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IconGroup);
