import PropTypes from "prop-types";
import React,  {useState} from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { multilanguage } from "redux-multilanguage";
import { logoutWithWithNormal } from "../../redux/actions/loginActions";
import { store } from "../..";
import { useEffect } from "react";
import { fetchCustomPages } from "../../redux/actions/commonActions";
import { Menu, Dropdown, Skeleton } from 'antd';
import { DownOutlined } from '@ant-design/icons';

const NavMenu = ({ isMobile = false, strings, menuWhiteClass, sidebarMenu, settingData }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation(); // Hook to get the current location
  const { currentState } = useSelector(
    (state) => ({ currentState: state.userData }),
    shallowEqual
  );

  const { customPages } = useSelector((state) => ({
    customPages: state.generalData?.customPages,
  }));
  const { entities } = customPages || {};

  const { logout } = currentState;
  if (logout) {
    history.push(`/login`);
    dispatch(logoutWithWithNormal(false));
  }

  useEffect(() => {
    store.dispatch(fetchCustomPages());
  }, []);
 
  let largeScreenmenuListing = (
    settingData?.custom_page_header?.map(custom_header => (
      <Menu.Item key={custom_header.label} style={{ height: "50px" }}>
        <NavLink 
          to={process.env.PUBLIC_URL + `/${custom_header.slug}`}
          activeClassName="active"
          style={{ color: location.pathname === `/${custom_header.slug}` ? '#c51515' : 'inherit' }}
        >
          {custom_header.label}
        </NavLink>
      </Menu.Item>
    ))
  )
  
  let mobileOtherMenu = (
    settingData?.custom_page_header?.map(({ label, slug }) => (
      <li key={label} >
        <a onClick={() => {
          history.push(`/${slug}`);
        }}>
          {label}
        </a>
      </li>
    ))
  )
  
  const dropdownMenu = (
    <Menu style={{ minWidth: '200px', borderRadius: '8px' }}>
      {
        largeScreenmenuListing
      }
    </Menu>
  );
  const isPathActive = () => {
    return settingData?.custom_page_header?.some(header => location.pathname === `${process.env.PUBLIC_URL}/${header.slug}`);
  };
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);
  
  return (
    <div
      className={` ${
        sidebarMenu ? "sidebar-menu" : `main-menu ${menuWhiteClass || ""}`
      } `}
    >
      <nav className="w-100">
        <ul>
          <li>
            {isLoading && (
              <Skeleton.Button
                className="image"
                active
                size="small"
                shape="round"
                style={{ marginTop: "28px" }}
              />
            )}
            {!isLoading && (
              <NavLink
                to={process.env.PUBLIC_URL + "/home"}
                activeClassName="active"
              >
                {strings["home"]}
              </NavLink>
            )}
          </li>

          <li>
            {isLoading && (
              <Skeleton.Button
                className="image"
                active
                size="small"
                shape="round"
                style={{ marginTop: "28px" }}
              />
            )}
            {!isLoading && (
              <NavLink
                to={process.env.PUBLIC_URL + "/shop"}
                activeClassName="active"
                onLoad={() => setLoading(false)}
              >
                Shop
              </NavLink>
            )}
          </li>
          <li className="">
            {isLoading && (
              <Skeleton.Button
                className="image"
                active
                size="small"
                shape="round"
                style={{ marginTop: "28px" }}
              />
            )}
            {!isLoading && (
              <NavLink
                to={process.env.PUBLIC_URL + "/quick-order"}
                activeClassName="active"
                onLoad={() => setLoading(false)}
              >
                {strings["quick_order"]}
              </NavLink>
            )}
          </li>
          {!isMobile
            ? settingData?.custom_page_header?.length > 0 && (
                <li style={{ margin: "10px" }}>
                  {isLoading && (
                    <Skeleton.Button
                      className="image"
                      active
                      size="small"
                      shape="round"
                      style={{ marginTop: "28px" }}
                    />
                  )}
                  {!isLoading && (
                    <Dropdown overlay={dropdownMenu} trigger={["hover"]}>
                      <a
                        onClick={(e) => e.preventDefault()}
                        style={{
                          color: isPathActive() ? "#c51515" : "inherit",
                          fontSize: isPathActive() ? "24px" : undefined,
                        }}
                      >
                        Others <DownOutlined />
                      </a>
                    </Dropdown>
                  )}
                </li>
              )
            : mobileOtherMenu}
           
        </ul>
      </nav>
    </div>
  );
};

NavMenu.propTypes = {
  menuWhiteClass: PropTypes.string,
  sidebarMenu: PropTypes.bool,
  strings: PropTypes.object,
};


export default multilanguage(NavMenu);
